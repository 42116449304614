import React from 'react'
import ReactDOM from "react-dom/client";
import ThemeProvider from 'providers/ThemeProvider'

// export const onServiceWorkerUpdateReady = () => window.location.reload(true)

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true)  window.location.reload()
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}


export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
